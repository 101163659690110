module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"theme_color":"#f69435","background_color":"#f69435","display":"browser","scope":"/","start_url":"/","name":"SwiftCloud","short_name":"SwiftCloud","icon":"src/images/favicon.png","icons":[{"src":"src/images/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"src/images/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"src/images/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bc96540e349fbd56077287f367952c3d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
